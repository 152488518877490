import * as Yup from "yup";

import { ICreateContactFieldsNew } from "models/contacts";
import { CountryCodeArr } from "utils/constants";

export const initialValues = (projectId: string): ICreateContactFieldsNew => {
	return {
		firstName: "",
		lastName: "",
		// fullName: "",
		contactDepartmentId: "",
		emailId: "",
		mobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
		nationality: "",
		isRestricted: false,
		shareConsent: true,
		licence: "",
		abn: "",
		wwcNumber: "",
		equityNumber: "",
		notes: "",
		unit: "",
		address: null,
		street: "",
		suburb: "",
		contactCity: "",
		contactState: "",
		contactCountry: "",
		pincode: "",
		lenderCompanyDetails: {
			companyName: "",
			spocName: "",
			spocMobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
			spocEmailId: "",
			website: "",
			unit: "",
			address: null,
			street: "",
			suburb: "",
			companyCity: "",
			companyState: "",
			companyCountry: "",
			pinCode: "",
			abn: "",
			acn: "",
			registrationNumber: "",
			publicLiabilityPolicy: "",
			provider: "",
			policyNumber: "",
			insurancePeriod: "",
			financialDetails: {
				accountName: "",
				accountNumber: "",
				accountBsb: "",
				accountSwiftCode: "",
				bankName: "",
				branchName: "",
				accountInstructions: "",
				abn: "",
				taxFileNo: "",
				haveSuperannuationAccount: "",
				superannuationFundName: "",
				memberNumber: "",
				smsfName: "",
				smsfAbn: "",
				smsfEsa: "",
				smsfFullName: "",
				smsfBankAccountName: "",
				smsfBsb: "",
				superannuationAccountNo: "",
			},
		},
		agentFirstName: "",
		agentLastName: "",
		agentEmailId: "",
		agentMobile: "",
		agencyDetails: {
			companyName: "",
			spocName: "",
			spocMobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
			spocEmailId: "",
			website: "",
			unit: "",
			address: null,
			street: "",
			suburb: "",
			companyCity: "",
			companyState: "",
			companyCountry: "",
			pinCode: "",
			abn: "",
			acn: "",
			registrationNumber: "",
			publicLiabilityPolicy: "",
			provider: "",
			policyNumber: "",
			insurancePeriod: "",
			financialDetails: {
				accountName: "",
				accountNumber: "",
				accountBsb: "",
				accountSwiftCode: "",
				bankName: "",
				branchName: "",
				accountInstructions: "",
				abn: "",
				taxFileNo: "",
				haveSuperannuationAccount: "",
				superannuationFundName: "",
				memberNumber: "",
				smsfName: "",
				smsfAbn: "",
				smsfEsa: "",
				smsfFullName: "",
				smsfBankAccountName: "",
				smsfBsb: "",
				superannuationAccountNo: "",
			},
		},
		managerFirstName: "",
		managerLastName: "",
		managerEmailId: "",
		managerMobile: "",
		managementCompanyDetails: {
			companyName: "",
			spocName: "",
			spocMobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
			spocEmailId: "",
			website: "",
			unit: "",
			address: null,
			street: "",
			suburb: "",
			companyCity: "",
			companyState: "",
			companyCountry: "",
			pinCode: "",
			abn: "",
			acn: "",
			registrationNumber: "",
			publicLiabilityPolicy: "",
			provider: "",
			policyNumber: "",
			insurancePeriod: "",
			financialDetails: {
				accountName: "",
				accountNumber: "",
				accountBsb: "",
				accountSwiftCode: "",
				bankName: "",
				branchName: "",
				accountInstructions: "",
				abn: "",
				taxFileNo: "",
				haveSuperannuationAccount: "",
				superannuationFundName: "",
				memberNumber: "",
				smsfName: "",
				smsfAbn: "",
				smsfEsa: "",
				smsfFullName: "",
				smsfBankAccountName: "",
				smsfBsb: "",
				superannuationAccountNo: "",
			},
		},
		financialDetails: {
			accountName: "",
			accountNumber: "",
			accountBsb: "",
			accountSwiftCode: "",
			bankName: "",
			branchName: "",
			accountInstructions: "",
			abn: "",
			taxFileNo: "",
			haveSuperannuationAccount: "",
			superannuationFundName: "",
			memberNumber: "",
			smsfName: "",
			smsfAbn: "",
			smsfEsa: "",
			smsfFullName: "",
			smsfBankAccountName: "",
			smsfBsb: "",
			superannuationAccountNo: "",
		},
		projectDetails: [
			{
				projectId: projectId,
				role: "",
				artistClassId: "",
				artistTypeId: "",
				characterName: "",
			},
		],
		profile_picture: null,
		project_role: "",
		dob: "",
		gender: "",
		food_allergies: "",
		medical_allergies: "",
		next_of_kin_contact_number: {
			countryCode: "61",
			nationalNumber: null,
			rawInput: "",
		},
		next_of_kin_name: "",
		next_of_kin_relationship: "",
		instagram: "",
		facebook: "",
		twitter: "",
		imdb: "",
	};
};

export const validationSchema = Yup.object().shape({
	firstName: Yup.string().required("Please enter your first name"),
	lastName: Yup.string().required("Please enter your last name"),
	emailId: Yup.string()
		.required("Please enter the email id")
		.email("Please enter a valid email"),
	mobile: Yup.object().shape({
		nationalNumber: Yup.string().required("Please enter the contact number"),
	}),
	contactDepartmentId: Yup.string().required("Please select a department"),
	abn: Yup.string()
		.matches(/^[0-9]{11}$/, "ABN must be 11 digits")
		.nullable(),
	// next_of_kin_contact_number: Yup.string()
	//   .matches(
	//     /^[0-9]{4}\s[0-9]{3}\s[0-9]{3}$/,
	//     "Contact number must be of 10 digits"
	//   )
	//   .nullable(),
	instagram: Yup.string()
		.matches(
			/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
			"Please enter a valid url"
		)
		.nullable(),
	facebook: Yup.string()
		.matches(
			/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
			"Please enter a valid url"
		)
		.nullable(),
	twitter: Yup.string()
		.matches(
			/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
			"Please enter a valid url"
		)
		.nullable(),
	imdb: Yup.string()
		.matches(
			/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
			"Please enter a valid url"
		)
		.nullable(),
	financialDetails: Yup.object().shape({
		smsfAbn: Yup.string()
			.matches(/^[0-9]{11}$/, "ABN must be 11 digits")
			.nullable(),
		smsfBsb: Yup.string()
			.matches(/^[0-9]{6}$/, "BSB must be 6 digits")
			.nullable(),
		superannuationAccountNo: Yup.string()
			.matches(/^[0-9]{10}$/, "Account number must be 10 digits")
			.nullable(),
	}),
	lenderCompanyDetails: Yup.object().shape({
		spocEmailId: Yup.string().email("Please enter a valid email").nullable(),
		// spocMobile: Yup.string()
		//   .matches(
		//     /^[0-9]{4}\s[0-9]{3}\s[0-9]{3}$/,
		//     "Contact number must be of 10 digits"
		//   )
		//   .nullable(),
		website: Yup.string()
			.matches(
				/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
				"Please enter a valid url"
			)
			.nullable(),
		abn: Yup.string()
			.matches(/^[0-9]{11}$/, "ABN must be 11 digits")
			.nullable(),
		acn: Yup.string()
			.matches(/^[0-9]{9}$/, "ACN must be 9 digits")
			.nullable(),
	}),
	agencyDetails: Yup.object().shape({
		spocEmailId: Yup.string().email("Please enter a valid email").nullable(),
		// spocMobile: Yup.string()
		//   .matches(
		//     /^[0-9]{4}\s[0-9]{3}\s[0-9]{3}$/,
		//     "Contact number must be of 10 digits"
		//   )
		//   .nullable(),
		website: Yup.string()
			.matches(
				/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
				"Please enter a valid url"
			)
			.nullable(),
		abn: Yup.string()
			.matches(/^[0-9]{11}$/, "ABN must be 11 digits")
			.nullable(),
		acn: Yup.string()
			.matches(/^[0-9]{9}$/, "ACN must be 9 digits")
			.nullable(),
	}),
	managementCompanyDetails: Yup.object().shape({
		spocEmailId: Yup.string().email("Please enter a valid email").nullable(),
		// spocMobile: Yup.string()
		//   .matches(
		//     /^[0-9]{4}\s[0-9]{3}\s[0-9]{3}$/,
		//     "Contact number must be of 10 digits"
		//   )
		//   .nullable(),
		website: Yup.string()
			.matches(
				/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
				"Please enter a valid url"
			)
			.nullable(),
		abn: Yup.string()
			.matches(/^[0-9]{11}$/, "ABN must be 11 digits")
			.nullable(),
		acn: Yup.string()
			.matches(/^[0-9]{9}$/, "ACN must be 9 digits")
			.nullable(),
	}),
	// project_role: Yup.string().required("Please mention the role"),
	// pincode: Yup.string().matches(/^[0-9]{4}$/, "Post Code must be of 4 digits"),
	// website: Yup.string().matches(
	//   /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
	//   "Please enter a valid url"
	// ),
	// next_of_kin_contact_number: Yup.string().matches(
	//   /^[0-9]{10}$/,
	//   "Contact number must be of 10 digits"
	// ),
	// agentMobile: Yup.string().matches(
	//   /^[0-9]{10}$/,
	//   "Contact number must be of 10 digits"
	// ),
	// agentEmailId: Yup.string().email("Please enter a valid email"),
	// managerMobile: Yup.string().matches(
	//   /^[0-9]{10}$/,
	//   "Contact number must be of 10 digits"
	// ),
	// managerEmailId: Yup.string().email("Please enter a valid email"),
	// lenderCompanyDetails: Yup.object().shape({
	//   pinCode: Yup.string().matches(
	//     /^[0-9]{4}$/,
	//     "Post Code must be of 4 digits"
	//   ),
	//   spocMobile: Yup.string().matches(
	//     /^[0-9]{10}$/,
	//     "Contact number must be of 10 digits"
	//   ),
	//   spocEmailId: Yup.string().email("Please enter a valid email"),
	//   website: Yup.string().matches(
	//     /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
	//     "Please enter a valid url"
	//   ),
	//   registrationNumber: Yup.string().matches(
	//     /^[0-9]{11}$/,
	//     "ABN must be of 11 digits"
	//   ),
	// }),
	// agencyDetails: Yup.object().shape({
	//   pinCode: Yup.string().matches(
	//     /^[0-9]{4}$/,
	//     "Post Code must be of 4 digits"
	//   ),
	//   spocMobile: Yup.string().matches(
	//     /^[0-9]{10}$/,
	//     "Contact number must be of 10 digits"
	//   ),
	//   spocEmailId: Yup.string().email("Please enter a valid email"),
	//   website: Yup.string().matches(
	//     /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
	//     "Please enter a valid url"
	//   ),
	//   registrationNumber: Yup.string().matches(
	//     /^[0-9]{11}$/,
	//     "ABN must be of 11 digits"
	//   ),
	// }),
	// managementCompanyDetails: Yup.object().shape({
	//   pinCode: Yup.string().matches(
	//     /^[0-9]{4}$/,
	//     "Post Code must be of 4 digits"
	//   ),
	//   spocMobile: Yup.string().matches(
	//     /^[0-9]{10}$/,
	//     "Contact number must be of 10 digits"
	//   ),
	//   spocEmailId: Yup.string().email("Please enter a valid email"),
	//   website: Yup.string().matches(
	//     /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
	//     "Please enter a valid url"
	//   ),
	//   registrationNumber: Yup.string().matches(
	//     /^[0-9]{11}$/,
	//     "ABN must be of 11 digits"
	//   ),
	// }),
	// projectDetails: Yup.array()
	//   .of(
	//     Yup.object().shape({
	//       projectId: Yup.string(),
	//       role: Yup.string(),
	//       artistTypeId: Yup.string(),
	//       artistClassId: Yup.string(),
	//       characterName: Yup.string(),
	//     })
	//   )
	//   .min(1, "Please add atleast one project"),
	// personalBankAccountDetails: Yup.object().shape({
	//   accountNumber: Yup.string(),
	//   accountName: Yup.string(),
	//   accountBsb: Yup.string(),
	//   bankName: Yup.string(),
	//   abn: Yup.string().matches(/^[0-9]{11}$/, "Must be 11 digits"),
	//   superannuationAccountNo: Yup.string(),
	//   superannuationFundName: Yup.string(),
	//   taxFileNo: Yup.string().matches(/^[0-9]{9}$/, "Must be 9 digits"),
	// }),
	// lenderBankAccountDetails: Yup.object().shape({
	//   accountNumber: Yup.string(),
	//   accountName: Yup.string(),
	//   accountBsb: Yup.string(),
	//   bankName: Yup.string(),
	//   abn: Yup.string().matches(/^[0-9]{11}$/, "Must be 11 digits"),
	//   superannuationAccountNo: Yup.string(),
	//   superannuationFundName: Yup.string(),
	//   taxFileNo: Yup.string().matches(/^[0-9]{9}$/, "Must be 9 digits"),
	// }),
	// agencyBankAccountDetails: Yup.object().shape({
	//   accountNumber: Yup.string(),
	//   accountName: Yup.string(),
	//   accountBsb: Yup.string(),
	//   bankName: Yup.string(),
	//   abn: Yup.string().matches(/^[0-9]{11}$/, "Must be 11 digits"),
	//   superannuationAccountNo: Yup.string(),
	//   superannuationFundName: Yup.string(),
	//   taxFileNo: Yup.string().matches(/^[0-9]{9}$/, "Must be 9 digits"),
	// }),
	// managementBankAccountDetails: Yup.object().shape({
	//   accountNumber: Yup.string(),
	//   accountName: Yup.string(),
	//   accountBsb: Yup.string(),
	//   bankName: Yup.string(),
	//   abn: Yup.string().matches(/^[0-9]{11}$/, "Must be 11 digits"),
	//   superannuationAccountNo: Yup.string(),
	//   superannuationFundName: Yup.string(),
	//   taxFileNo: Yup.string().matches(/^[0-9]{9}$/, "Must be 9 digits"),
	// }),
});

export const createAddContactPayload = (values: any, newAgent: boolean) => {
	const {
		mobile,
		profile_picture,
		address,
		contactCity,
		contactState,
		contactCountry,
		pincode,
		suburb,
		street,
		contactDepartmentId,
		project_role,
		dob,
		gender,
		food_allergies,
		medical_allergies,
		next_of_kin_contact_number,
		next_of_kin_name,
		next_of_kin_relationship,
		instagram,
		facebook,
		twitter,
		imdb,
		lenderCompanyDetails,
		agencyDetails,
		managementCompanyDetails,
		financialDetails,
		...restFields
	} = values;

	const profileJSON = {
		project_role,
		dob,
		gender,
		food_allergies,
		medical_allergies,
		next_of_kin_contact_number: Boolean(
			next_of_kin_contact_number?.nationalNumber
		)
			? `${
					CountryCodeArr.find(
						(x) => x.id === next_of_kin_contact_number?.countryCode
					)?.name
			  } ${next_of_kin_contact_number?.nationalNumber}`
			: "",
		next_of_kin_name,
		next_of_kin_relationship,
		instagram,
		facebook,
		twitter,
		imdb,
	};

	const {
		spocMobile: lenderMobile,
		address: lenderCompanyAddress,
		companyCity: lenderCity,
		companyState: lenderState,
		companyCountry: lenderCountry,
		pinCode: lenderPincode,
		suburb: lenderSuburb,
		street: lenderStreet,
		financialDetails: lenderBankAccountDetails,
		...restLenderCompanyDetails
	} = lenderCompanyDetails;
	const {
		spocMobile: agencyMobile,
		address: agencyAddress,
		companyCity: agencyCity,
		companyState: agencyState,
		companyCountry: agencyCountry,
		pinCode: agencyPincode,
		suburb: agencySuburb,
		street: agencyStreet,
		financialDetails: agencyBankAccountDetails,
		...restAgencyDetails
	} = agencyDetails;
	const {
		spocMobile: managementCompanyMobile,
		address: managementCompanyAddress,
		companyCity: managementCompanyCity,
		companyState: managementCompanyState,
		companyCountry: managementCompanyCountry,
		pinCode: managementCompanyPincode,
		suburb: managementCompanySuburb,
		street: managementCompanyStreet,
		financialDetails: managementBankAccountDetails,
		...restManagementCompanyDetails
	} = managementCompanyDetails;

	const {
		id,
		__typename,
		companyCity,
		companyState,
		companyCountry,
		...restFilteredAgencyDetails
	} = restAgencyDetails;

	const {
		haveSuperannuationAccount,
		superannuationFundName,
		memberNumber,
		smsfName,
		smsfAbn,
		smsfEsa,
		smsfFullName,
		smsfBankAccountName,
		smsfBsb,
		superannuationAccountNo,
		...restFinancialDetails
	} = financialDetails;
	const financialDetailsJSON = {
		haveSuperannuationAccount,
		superannuationFundName,
		memberNumber,
		smsfName,
		smsfAbn,
		smsfEsa,
		smsfFullName,
		smsfBankAccountName,
		smsfBsb,
		superannuationAccountNo,
	};

	const {
		haveSuperannuationAccount: lenderHave,
		smsfName: lenderSName,
		smsfAbn: lenderSAbn,
		smsfEsa: lenderSEsa,
		smsfFullName: lenderSFullName,
		smsfBankAccountName: lenderSBankAccountName,
		smsfBsb: lenderSBsb,
		...restLenderFinancialDetails
	} = lenderBankAccountDetails;
	const {
		haveSuperannuationAccount: agencyHave,
		smsfName: agencySName,
		smsfAbn: agencySAbn,
		smsfEsa: agencySEsa,
		smsfFullName: agencySFullName,
		smsfBankAccountName: agencySBankAccountName,
		smsfBsb: agencySBsb,
		...restAgencyFinancialDetails
	} = agencyBankAccountDetails;
	const {
		haveSuperannuationAccount: mgmtHave,
		smsfName: mgmtSName,
		smsfAbn: mgmtSAbn,
		smsfEsa: mgmtSEsa,
		smsfFullName: mgmtSFullName,
		smsfBankAccountName: mgmtSBankAccountName,
		smsfBsb: mgmtSBsb,
		...restMgmtFinancialDetails
	} = managementBankAccountDetails;

	return {
		...restFields,
		mobile: `${
			CountryCodeArr.find((x) => x.id === mobile.countryCode)?.name
		} ${mobile.nationalNumber}`,
		profile: JSON.stringify(profileJSON),
		address: address?.freeformAddress || "",
		contactCountry: address?.country || "",
		contactState: address?.countrySubdivision || "",
		contactCity: address?.municipality || "",
		pincode: address?.postalCode || "",
		suburb: address?.municipalitySubdivision || "",
		street: address?.streetName || "",
		...(Boolean(contactDepartmentId) && { contactDepartmentId }),
		agencyDetails: {
			...(typeof agencyAddress !== "string" && agencyAddress !== null
				? {
						address: agencyAddress?.freeformAddress || "",
				  }
				: { address: "" }),
			...(newAgent
				? {
						...restAgencyDetails,
						...(Boolean(agencyMobile?.nationalNumber) && {
							spocMobile: `${
								CountryCodeArr.find(
									(x) => x.id === agencyMobile.countryCode
								)?.name
							} ${agencyMobile.nationalNumber}`,
						}),
						companyCountry: agencyAddress?.country || "",
						companyState: agencyAddress?.countrySubdivision || "",
						companyCity: agencyAddress?.municipality || "",
						pinCode: agencyAddress?.postalCode || "",
						suburb: agencyAddress?.municipalitySubdivision || "",
						street: agencyAddress?.streetName || "",
						financialDetails: { ...restAgencyFinancialDetails },
				  }
				: {
						...(Boolean(agencyMobile?.nationalNumber) && {
							spocMobile: `${
								CountryCodeArr.find(
									(x) => x.id === agencyMobile.countryCode
								)?.name
							} ${agencyMobile.nationalNumber}`,
						}),
						...restFilteredAgencyDetails,
						...(typeof agencyAddress !== "string" &&
							agencyAddress !== null && {
								companyCountry:
									agencyAddress?.country || companyCountry,
								companyState:
									agencyAddress?.countrySubdivision || companyState,
								companyCity: agencyAddress?.municipality || companyCity,
								pinCode: agencyAddress?.postalCode || "",
								suburb: agencyAddress?.municipalitySubdivision || "",
								street: agencyAddress?.streetName || "",
							}),
						financialDetails: { ...restAgencyFinancialDetails },
				  }),
		},
		lenderCompanyDetails: {
			...(typeof lenderCompanyAddress !== "string" &&
			lenderCompanyAddress !== null
				? {
						address: lenderCompanyAddress?.freeformAddress || "",
				  }
				: { address: "" }),
			...(Boolean(lenderMobile?.nationalNumber) && {
				spocMobile: `${
					CountryCodeArr.find((x) => x.id === lenderMobile.countryCode)
						?.name
				} ${lenderMobile.nationalNumber}`,
			}),
			companyCountry: lenderCompanyAddress?.country || "",
			companyState: lenderCompanyAddress?.countrySubdivision || "",
			companyCity: lenderCompanyAddress?.municipality || "",
			pinCode: lenderCompanyAddress?.postalCode || "",
			suburb: lenderCompanyAddress?.municipalitySubdivision || "",
			street: lenderCompanyAddress?.streetName || "",
			financialDetails: { ...restLenderFinancialDetails },
			...restLenderCompanyDetails,
		},
		managementCompanyDetails: {
			...(typeof managementCompanyAddress !== "string" &&
			managementCompanyAddress !== null
				? {
						address: managementCompanyAddress?.freeformAddress || "",
				  }
				: { address: "" }),
			...(Boolean(managementCompanyMobile?.nationalNumber) && {
				spocMobile: `${
					CountryCodeArr.find(
						(x) => x.id === managementCompanyMobile.countryCode
					)?.name
				} ${managementCompanyMobile.nationalNumber}`,
			}),
			companyCountry: managementCompanyAddress?.country || "",
			companyState: managementCompanyAddress?.countrySubdivision || "",
			companyCity: managementCompanyAddress?.municipality || "",
			pinCode: managementCompanyAddress?.postalCode || "",
			suburb: managementCompanyAddress?.municipalitySubdivision || "",
			street: managementCompanyAddress?.streetName || "",
			financialDetails: { ...restMgmtFinancialDetails },
			...restManagementCompanyDetails,
		},
		financialDetails: {
			...restFinancialDetails,
			superannuationData: JSON.stringify(financialDetailsJSON),
		},
	};
};

export const validateProjectsArr = (projects: any, setProjectErrors: any) => {
	let errors: string[] = [];
	for (let i = 0; i < projects.length; i++) {
		errors.push("");
	}
	for (let i = 0; i < projects.length; i++) {
		if (projects[i].projectId === "") {
			errors[i] = "Please select a project";
		}
	}
	setProjectErrors(errors);
	return errors;
};

export const validateProjectErrors = (errors: string[]) => {
	let flag = 0;
	for (let i = 0; i < errors.length; i++) {
		if (errors[i]) {
			flag = 1;
			break;
		}
	}
	return flag === 0;
};
